updateOutsource.vue<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10" class="header-row">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.outsource_add") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                  :loading="loadingButton"
                  :disabled="loadingButton"
                  @click="submitParty(true)"
                  type="success"
              >
                {{ $t("message.save_and_close") }}
              </el-button>
              <el-button @click="resetForm('formParty')">
                {{ $t("message.close") }}
              </el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-main>
      <el-form :model="form" :rules="rules" ref="formParty">
        <el-row :gutter="24">
          <!-- Партнёрская клиника -->
          <el-col :span="10">
            <div class="mt-2 width-100 d-flex flex-wrap">
              <el-select
                  class="autoInput"
                  v-model="form.partner_clinic_id"
                  filterable
                  clearable
                  :placeholder="$t('message.partner_clinic')"
              >
                <el-option
                    v-for="item in partnerClinics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>

          <!-- Выбор курьера -->
          <el-col :span="10">
            <div class="mt-2 width-100 d-flex flex-wrap align-items-center">
              <courier
                  v-model="form.courier_id"
                  :id="model.courier_id"
                  :partner_clinic_id="form.partner_clinic_id"
                  class="courier-input"
              ></courier>
              <el-button
                  class="p-1 plus-button"
                  size="small"
                  icon="el-icon-plus"
                  @click="drawerCreate = true"
              >
              </el-button>
            </div>
          </el-col>

          <!-- Поиск -->
          <el-col :span="10">
            <form
                class="mt-2 width-100 d-flex flex-wrap"
                v-on:submit.prevent="findOrder"
            >
              <el-input
                  :placeholder="$t('message.enter_id')"
                  v-model="id"
                  class="input-with-select"
              >
                <el-button
                    @click="findOrder()"
                    slot="append"
                    icon="el-icon-search"
                ></el-button>
              </el-input>
            </form>
          </el-col>

          <el-col :span="8" v-if="order_show">
            <el-select
                class="mt-2 width-100 d-flex flex-wrap align-items-center"
                v-model="selected_order_service_id"
                filterable
                clearable
            >
              <el-option
                  v-for="(service, key) in tableServicesData"
                  :key="key"
                  :label="service.name"
                  :value="key"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :span="2" v-if="order_show">
            <el-button
                class="mt-2 p-1 plus-button"
                size="small"
                @click="addToList"
            >{{ $t('message.add') }}</el-button>
          </el-col>

          <!-- Таблица заказать услугу -->
          <el-col :span="24" class="mt-2">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ $t("message.services") }}</span>
              </div>
              <div>
                <el-table :data="selectedServiceData" style="width: 100%">
                  <el-table-column prop="order_id" :label="$t('message.order_id')" align="center"></el-table-column>
                  <el-table-column prop="name" :label="$t('message.service_name')" width="280" align="center"></el-table-column>
                  <el-table-column prop="price" :label="$t('message.price2')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.price | formatMoney }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="code" :label="$t('message.code')" align="center"></el-table-column>
                  <el-table-column prop="lifetime" label="Срок услуги" align="center"></el-table-column>
                  <el-table-column label="Actions" align="center">
                    <template slot-scope="scope">
                      <div class="centered-content">
                        <el-button
                            type="danger"
                            size="mini"
                            @click="removeFromList(scope.$index)">
                          {{ $t('message.delete') }}
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-form>

    </el-main>
    <el-drawer
        :visible.sync="drawerCreate"
        size="50%"
        :wrapper-closable="false"
        @close="reloadIfChanged('drawerCreate')"
        :append-to-body="true"
        class="mytab_IdP"
    >
      <courier-create
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
      ></courier-create>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import courier from '@/components/inventory-select/courier-select-search.vue';
import courierCreate from '@/views/couriers/components/crm-create.vue';

export default {
  components: { courier, courierCreate },
  data() {
    return {
      drawerCreate: false,
      reopenUpdate: false,
      loadingButton: false,
      loading: false,
      order_show: false,
      tableServicesData: [],
      id: "",
      selected_order_service_id: null,
      selectedServiceData: [],
      form: {
        partner_clinic_id: null,
        courier_id: null,
      }
    };
  },
  computed: {
    ...mapGetters({
      rules: "outsourceParty/rules",
      model: "orders/relation_model",
      partnerClinics: 'partnerClinic/inventory_all',
    }),
  },
  mounted() {
    if (!this.partnerClinics.length) {
      this.loadPartnerClinic();
    }
  },
  methods: {
    ...mapActions({
      save: "outsourceParty/store",
      showModel: "orders/relationShow",
      loadPartnerClinic: "partnerClinic/inventoryAll",
    }),
    opened() {
      this.afterOpened()
    },
    afterOpened() {
      this.loadModel();
      this.findOutsource();
      this.order_show = true;
      this.id = this.model.id;
      this.search_input = true;
    },
    loadModel() {
      this.tablePatientData = [JSON.parse(JSON.stringify(this.model.patient))];
      this.tableOrderData = [JSON.parse(JSON.stringify(this.model))];
      this.tableServicesData = JSON.parse(JSON.stringify(this.model.order_services));
    },
    findOrder() {
      this.loading = true;
      const query = {id: this.id, relation: true, free_orders: true};
      this.showModel(query)
          .then((res) => {
            if (res.data.order.status === 'doctor' || res.data.order.status === 'doctor_free') {
              this.order_show = true;
              this.loadModel();
            } else {
              this.$notify({
                title: "Внимание",
                type: "warning",
                offset: 130,
                message: "Неверный статус заказа",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.order_show = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              dangerouslyUseHTMLString: true,
              message: "Заказ не найден",
            });
          });
    },
    resetForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
      this.$emit("c-close", {drawer: "drawerCreatePatient"});
      this.empty();
    },
    submitParty(close = true) {
      this.$refs["formParty"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.order_services = this.selectedServiceData;
          this.save(this.form)
            .then((res) => {
              this.$alert(res.message);
              this.form = {};
              if (close) {
                this.loadingButton = false;
                this.$emit("c-close", {
                  reload: true,
                  drawer: "drawerCreatePatient",
                });
                this.$refs["formParty"].resetFields();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    reloadIfChanged(modal) {
      this[modal] = false;
      if (this.reloadList) {
        this.fetchData();
        this.afterFetchData();
      }
    },
    fetchData() {
      const query = {id: this.order_payments.id, relation: true};
      this.showModel(query)
          .then((res) => {
            this.drawerUpdate = true;
          })
          .catch(() => {
          });
    },
    afterFetchData() {
      this.reloadList = false;
    },
    addToList() {
      const service = this.tableServicesData[this.selected_order_service_id];
      if (!this.selectedServiceData.find((item) => item.order_service_id === service.order_service_id)) {
        this.selectedServiceData.push(service);
        this.selected_order_service_id = null;
      } else {
        this.$notify({
          title: "Внимание",
          type: "warning",
          offset: 130,
          message: 'Эта услуга уже есть в списке',
        });
      }
    },
    removeFromList(index) {
      this.selectedServiceData.splice(index, 1);
    },
    empty() {
      this.form = [];
      this.id = null;
      this.selected_order_service_id = null;
      this.selectedServiceData = [];
      this.order_show = false;
    }
  }
};
</script>
<style>
.courier-input {
  flex: 1;
}
.actions-column .centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
